riot.tag2('course-filters', '<input type="checkbox" id="toggle_filters" name="toggle"> <label for="toggle_filters" class="btn btn-success">Filter by Subject</label> <form onsubmit="{apply}" class="filters course_filters" autocomplete="off"> <input name="q" id="classes_q" class="form-control" placeholder="Search Classes" onkeyup="{search}" onblur="{apply}" id="courseSearch"> <div class="list_filter btn-group-vertical"> <label class="btn btn-default {opts.active_subject?\' \':\'selected\'}" onclick="{click}">All Subjects</label> </div> <div each="{CLASS_SUBJECTS}" class="list_filter btn-group-vertical"> <label if="{active_courses + inactive_courses}" onclick="{parent.click}" class="btn btn-default {(parent.opts.active_subject == value)?\'selected\':\' \'}">{name}</label> </div> </form>', '', '', function(opts) {

  this.click = function(e) {
    opts.filterSubjects(e.item?e.item.value:"");
    opts.active_subject = (e.item || {}).value;
    this.toggle_filters.checked = false;
    riot.update();
  }.bind(this)
  this.apply = function(e) {
    opts.filterSearch(this.classes_q.value);
    this.toggle_filters.checked = false;
    riot.update();
  }.bind(this)
  this.search = function(e) {
    opts.filterSearch(this.classes_q.value);
    riot.update();
  }.bind(this)
  this.search = uR.debounce(this.search);
});

riot.tag2('course-list', '<div class="card {(SITE.tag == \'ion\')?\'border-secondary bg-dark\':\'\'} mb-3" each="{opts.courses}" if="{visible}"> <a href="{url}" class="course text-decoration-none {well_class}" id="course_{id}"> <div class="price border rounded"> {(fee > 0 && next_time != 0)?(⁗$⁗+fee):⁗FREE⁗} </div> <div class="row g-0 text-center text-md-start"> <div class="col-md-4 col-sm-12 text-center text-md-start"> <img riot-src="{im.url}" width="{im.width}" height="{im.height}" class="img-fluid"> <div class="enrolled-status rounded" status="{enrolled_status}"></div> </div> <div class="col-md-8 card-body details p-1"> <div class="d-md-flex mb-auto justify-content-between"> <div> <div class="subjects d-none" if="{TXRX.DEBUG}"><span each="{subject in subject_names}">{subject}</span></div> <div class="card-title title">{name}</div> <div class="card-text description px-3 px-md-0">{short_description}</div> </div> </div> <div class="d-flex mt-auto justify-content-between"> <div class="sessions" if="{active_sessions.length}"> <span class="next_session {active_sessions[0].closed_status}">{active_sessions[0].short_dates}</span> <div class="pull-right"> <span class="full_sessions" if="{full_sessions.length}"> [{full_sessions.length} Full<span class=" hidden-xs"> Session{(full_sessions.length > 1)?⁗s⁗:⁗⁗}</span>] </span> <span class="open_sessions" if="{open_sessions.length}"> [{open_sessions.length} Open<span class=" hidden-xs"> Session{(open_sessions.length > 1)?⁗s⁗:⁗⁗}</span>] </span> </div> <div class="card-text enrolled-status" data-status="{enrolled_status}"></div> </div> </div> </div> </div> </a> </div>', '', '', function(opts) {
});

riot.tag2('session-list', '<h1 class="fee page_title" if="{opts.active_sessions}"> <span if="{opts.fee}">${opts.fee}</span> <span if="{!opts.fee}">Free</span> </h1> <h3 if="{opts.active_sessions}">Upcoming Sessions:</h3> <div class="session well" id="s{id}" each="{opts.active_sessions}"> <a if="{uR.auth.user.is_superuser || instructor_pk == uR.auth.user.id}" href="/classes/instructor_session/{id}/" class="instructor-link fa fa-edit"></a> <a if="{uR.auth.user.is_superuser}" href="/admin/course/session/{id}/" class="admin-link fa fa-pencil-square"></a> <div class="date" each="{classtimes}"> {moment.format(⁗ddd MMM D⁗)} {start_time} - {end_time} </div> <div class="instructor">with {instructor_name}</div> <b class="full" if="{closed_status == \'full\'}">This session is full</b> <b class="full" if="{closed_status == \'past\'}">This session has passed</b> <b class="full" if="{closed_status == \'private\'}">This session is private</b> <b class="full" if="{closed_status == \'cancelled\'}">This session has been canceled. If you were enrolled and have not received an email, please contact <a href="mailto:{uR.config.support_email}">{uR.config.support_email}</a></b> <div if="{!closed_status && fee}"> <add-to-cart product_id="{product_id}" add_text="Add this session to cart"></add-to-cart> </div> <div if="{!closed_status && !fee}"> <button class="btn btn-success rsvp" onclick="{parent.rsvp}" if="{!rsvpd && uR.auth.user}"> RSVP for this event</button> <button class="btn btn-danger unrsvp" onclick="{parent.unrsvp}" if="{rsvpd && uR.auth.user}"> Cancel RSVP</button> <a if="{!uR.auth.user}" href="{uR.urls.auth.login}">Login to RSVP</a> <div class="alert alert-warning" if="{message}">{message}</div> </div> </div> <a if="{user.is_superuser && opts.past_session_count}" class="btn btn-success btn-block" onclick="{loadPastSessions}"> Show {opts.past_session_count} Archived Sessions</a>', '', '', function(opts) {

  this.user = uR.auth.user;
  var self = this;
  this.add = function(e) {
    uR.drop.saveCartItem(e.item.product_id,1,this);
  }.bind(this)
  this.viewCart = function(e) {
    $("#cartModal").modal({show:true});
  }.bind(this)
  this.loadPastSessions = function(e) {
    var target = e.target;
    target.setAttribute("ur-loading","loading");
    $.get(
      '/classes/past_sessions.json?id='+self.opts.id,
      {},
      function(data) {
        target.removeAttribute("ur-loading","loading");
        self.opts.active_sessions = self.opts.active_sessions.concat(data);
        self.opts.past_session_count = 0;
        self.update();
      },
      'json'
    );
  }.bind(this)
  function _rsvp(e,url) {
    var target = document.getElementById("s"+e.item.id);
    target.setAttribute("ur-loading","loading");
    $.get(
      url,
      function(data) {
        target.removeAttribute("ur-loading","loading");
        uR.auth.user.enrollments[e.item.id] = data.quantity;
        e.item.message = data.message;
        self.update();
      },
      "json"
    );
  }
  this.rsvp = function(e) {
    var url = "/classes/rsvp/"+e.item.id+"/";
    _rsvp(e,url);
  }.bind(this)
  this.unrsvp = function(e) {
    var url = "/classes/unrsvp/"+e.item.id+"/";
    _rsvp(e,url);
  }.bind(this)
  function showSmartTime(moment) {
    if (moment.minutes() == 0) {
      if (moment.hours() == 0) { return "midnight" }
      if (moment.hours() == 12) { return "noon" }
      return moment.format("ha")
    }
    return moment.format("h:mma")
  }
  this.on("update",function() {
    uR.forEach(this.opts.active_sessions,function(session) {
      var user = uR.auth.user;
      if (window.location.search.indexOf("overbook="+session.id) != -1) { session.closed_status = ""; }
      session.fee = self.opts.fee;
      if (user && user.enrollments) { session.rsvpd = user.enrollments[session.id]; }
      session.incart = false;
      uR.forEach(session.classtimes,function(classtime) {
        classtime.moment = moment(classtime.start);
        classtime.start_time = showSmartTime(classtime.moment);
        classtime.end_moment = moment(classtime.end);
        classtime.end_time = showSmartTime(classtime.end_moment);
      });
      if (session.product_id) { uR.drop.product_on_page = uR.drop.products[session.product_id]; }
    });
  });
});

riot.tag2('category-list', '<button class="btn btn-default btn-block {active:!uR.drop.active_category}" onclick="{click}"> Any Category</button> <button class="btn btn-default btn-block {active:uR.drop.active_category==category.id}" onclick="{parent.click}" each="{category,i in uR.drop.categories}"> {category.name}</button>', '', '', function(opts) {

  this.on("mount",function() {
    this.ajax({
      url: "/shop/categories.js",
      success: function(data) {
        uR.drop.categories = data.categories;
        uR.drop.active_category = undefined;
      },
    });
  });

  this.click = function(e) {
    uR.drop.active_category = e.item && e.item.category && e.item.category.id;
    uR.drop.visible = 18;
    uR.drop.updateTags();
  }.bind(this)
});

riot.tag2('product-list', '<div class="row"> <product each="{products}" class="{parent.className}"></product> <product-admin each="{admin_products}" class="col-sm-6 col-md-4"></product-admin> </div> <button class="btn btn-warning btn-block" onclick="{more}" if="{uR.drop.visible<this.max_products}"> Load More</button>', '', '', function(opts) {

  var self = this;
  this.on("mount",function() {
    this.className = this.opts.className || "col-sm-6 col-md-4";
    uR.drop.visible = 18;
    this.update();
    if (window.PRODUCTS_EXTRA) {
      uR.forEach(uR.drop.products_list,function(p) {
        var extra  = window.PRODUCTS_EXTRA[p.id] || {};
        for (var key in extra) {
          p[key] = extra[key]
        }
      });
    }
  });
  this.on("update",function() {
    if (!uR.drop.products) { return }
    if (this.opts.ids) {
      this.products = [];
      uR.forEach(this.opts.ids,function(id) { self.products.push(uR.drop.products[id]); });
    } else {
      this.products = uR.drop.products_list;
    }
    if (this.opts.model_slugs) {
      var model_slugs = this.opts.model_slugs.split(",");
      this.products = this.products.filter( function(p) { return model_slugs.indexOf(p.model_slug) != -1; })
    }
    if (uR.drop.active_category) {
      this.products = this.products.filter(function(p){
        return p.category_ids && p.category_ids.indexOf(uR.drop.active_category) != -1;
      });
    }
    this.max_products = this.products.length;
    this.products = this.products.slice(0,uR.drop.visible);
    if (window.PRODUCTS_EXTRA) {
      this.admin_products = this.products;
      this.products = [];
    }
  });
  this.more = function(e) {
    uR.drop.visible += 12;
  }.bind(this)
});

riot.tag2('product', '<div class="well {out-of-stock:in_stock==0}"> <div class="img" riot-style="background-image: url({thumbnail})"></div> <div class="bottom"> <div class="name"> <a href="/admin/{model_slug.replace(\'.\',\'/\')}/{id}/" if="{uR.auth.user.is_superuser}" class="superuseronly fa fa-pencil-square"></a> {display_name} </div> <div class="row"> <div class="col-xs-6 price"> ${unit_price} <span if="{quantity}">x {quantity}</span> </div> <div class="col-xs-6"> <button class="btn btn-success btn-block" onclick="{addToCart}" if="{!quantity}">Add to Cart</button> <button class="btn btn-primary btn-block" onclick="{uR.drop.openCart}" if="{quantity}">Checkout</button> </div> </div> </div> </div>', '', '', function(opts) {

  var update_timeout;
  this.addToCart = function(e) {

    var widget = uR.drop._addToCart[this.model_slug] || uR.drop._addToCart[this.id];
    if (widget) { widget({product: uR.drop.products[this.id]}) }
    else { uR.drop.saveCartItem(this.id, 1, this); }
  }.bind(this)
});

riot.tag2('product-admin', '<div class="well"> <div class="img {out-of-stock:in_stock==0}"> <img riot-src="{img.url}"> </div> <div class="name">{display_name}</div> <div class="price"> ${price} <span class="pull-right">In Stock: {in_stock||⁗null⁗}</span> </div> <div class="row"> <div class="col-xs-6"> <a href="/admin/store/consumable/{pk}/" class="fa fa-pencil-square btn btn-primary btn-block"> Edit</a> </div> <div class="col-xs-6"> <a href="{purchase_url}" class="btn btn-info btn-block {hidden:!purchase_url}"> <i class="fa fa-shopping-cart"></i> {purchase_domain}</a> </div> </div> <div class="flexy"> <button onclick="{add1}" class="btn btn-success" if="{purchase_quantity != 1}"> +1</button> <button onclick="{subtract1}" class="btn btn-danger" if="{purchase_quantity != 1}"> -1</button> <button onclick="{add}" class="btn btn-success"> +{purchase_quantity}</button> <button onclick="{subtract}" class="btn btn-danger"> -{purchase_quantity}</button> </div> </div>', '', '', function(opts) {

  var self = this;
  function modify(quantity) {
    $.post(
      '/shop/admin/add/',
      {quantity:quantity,pk:self.pk},
      function(data) {
        self.in_stock = data;
        self.update();
      }
    )
  }
  this.add1 = function(e) {
    modify(1);
  }.bind(this)
  this.subtract1 = function(e) {
    modify(-1);
  }.bind(this)
  this.add = function(e) {
    modify(e.item.purchase_quantity);
  }.bind(this)
  this.subtract = function(e) {
    modify(-e.item.purcase_quantity);
  }.bind(this)
});


(function() {
  var _routes = {
    "^/redtape/(\\d+)/(.*)/": function(path,data) {
      uR.ajax({
        url: "/durf/redtape/document/"+data.matches[1]+"/",
        success: function(data) {
          uR.mountElement("ur-document",data);
        },
      });
    },
  }
  uR.addRoutes(_routes);
})();

riot.tag2('ur-document', '<div class="{theme.outer}"> <div class="{theme.header}"><h3>{opts.name}</h3></div> <div class="{theme.inner}"> <div class="inner-content"></div> <ur-form method="POST" action="/redtape/document/add/{opts.id}/" autosave="true"></ur-form> </div> </div>', '', '', function(opts) {

  this.on("mount",function() {
    this.update();
    this.root.querySelector(".inner-content").innerHTML = this.opts.rendered_content;
    document.title = this.opts.name;
    window.form = this.tags['ur-form'];
  });
  this.ajax_success = function(data,request) {
    uR.alert(data.ur_alert_success,{cancel: function() { uR.route("/"); }});
    this.tags['ur-form'].clear();
  }.bind(this)
});

uR.ready(function() {
  uR.addRoutes({
    "^/notify/$":uR.auth.loginRequired(function(path,data) { uR.mountElement("ur-notify",data); }),
  });
});

riot.tag2('ur-notify', '<p class="lead col-sm-12"> Change your <a href="/notify/settings/">notification settings</a> to choose to be notified by text, email, or none. </p> <div class="col-sm-6"> <p class="lead" if="{!future.length && !past.length}"> You have no notifications </p> <div if="{future.length}"> <h3>New Notifications</h3> <ul class="{uR.theme.list}"> <li each="{future}" class="{className}"><a href="{url}">{message}</a></li> </ul> </div> <div if="{past.length}"> <h3>Past Notifications</h3> <ul class="{uR.theme.list}"> <li each="{past}" class="{className}"><a href="{url}">{message}</a></li> </ul> </div> <div if="{older}"> <a onclick="{loadMore}">View Older...</a> </div> </div> <div class="col-sm-6" if="{follows}"> <h3>Things You Are Following</h3> <ul class="{uR.theme.list}"> <li each="{follows}" class="{className}"> <a href="{url}">{name}</a> <a class="{uR.theme.list_right} fa fa-close" href="{unfollow_url}" if="{!deleted}"> </a> </li> <li if="{follows.length > 1}" class="{uR.theme.list_item_danger}"> <a href="/notify/unsubscribe/notify_course/{uR.auth.user.id}">Unfollow All</a> </li> </ul> </div>', '', '', function(opts) {

  var self = this;
  this.on("mount",function() {
    this.ajax({
      url: "/api/notify/feed/",
      success: function(data) {
        self.data = data;
      },
    });
    this.ajax({
      url: "/api/notify/follow/",
      success: function(data) {
        self.follows = data;
      },
    });
  });
  this.on("update",function() {
    if (!this.follows || !this.data) { return; }
    this.ready = true;
    this.future = [];
    this.past = [];
    this.older = 0;
    uR.forEach(this.data || [],function(notification) {
      (moment(new Date(notification.expires))<moment()?this.past:this.future).push(notification);
      notification.className = (notification.target_type || "").replace(/\./g,"")+" "+uR.theme.list_item;
    }.bind(this));
    uR.forEach(this.follows,function(f) { f.className = uR.theme.list_item });
  });
  this.loadMore = function(e) {
    alert('not implmented');
  }.bind(this)
});

uR.ready(function() { riot.mount("money-widget") });

riot.tag2('money-widget', '<div class="well"> <div class="btn-group first"> <div each="{number in numbers}" class="btn btn-{number == parent.selected_value?\'primary\':\'default\'}"> <input riot-value="{number}" type="radio" id="money_button_{number}" onchange="{setIt}" name="money_button"> <label for="money_button_{number}">${number}</label> </div> <div class="btn btn-{other?\'primary\':\'default\'}"> <input type="text" min="1" step="1" onkeyup="{setOther}" onfocus="{setOther}" onblur="{setother}" id="money_other"> <label for="money_other">Other</label> </div> </div> <form action="https://www.paypal.com/cgi-bin/webscr" method="post" style="display: none;" id="donation-form"> <input type="hidden" name="cmd" value="_donations"> <input type="hidden" name="business" value="txrxlabs@gmail.com"> <input type="hidden" name="a3" riot-value="{selected_value}"> <input type="hidden" name="p3" value="1"> <input type="hidden" name="t3" value="M"> <input type="hidden" name="src" value="1"> <input type="hidden" name="sra" value="1"> <input type="hidden" name="amount" riot-value="{selected_value}"> <input name="notify_url" type="hidden" value="https://txrxlabs.org/tx/rx/ipn/handler/"> <input name="cancel_return" type="hidden" value="https://txrxlabs.org/support/"> <input name="return" type="hidden" value="https://txrxlabs.org/"> <input type="hidden" name="custom" riot-value="{opts.description || \'support page donation\'}"> <input name="on0" type="hidden" value="Donation"> <input name="os0" type="hidden" value="Monthly"> <input type="hidden" name="currency_code" value="USD"> </form> <div class="btn-group second"> <button onclick="{pay}" data-cmd="_donations" class="btn btn-primary"> {opts.donate_text || ⁗One-time gift⁗}</button> <button onclick="{pay}" data-cmd="_xclick-subscriptions" class="btn btn-primary" if="{!opts.hide_monthly}">Monthly gift</button> </div> <div if="{error}" class="alert alert-danger">Please select an amount</div> </div>', '', '', function(opts) {


  this.numbers = [25,50,150,500];
  this.setIt = function(e) {
    this.error = false;
    this.other = false;
    this.selected_value = e.item.number;
  }.bind(this);
  this.setOther = function(e) {
    this.error = false;
    this.other = true;
    this.selected_value = e.target.value.replace(/[^\d]+/g,'');
    if (this.root.querySelector(":checked")) { this.root.querySelector(":checked").checked = false; }
  }.bind(this)
  this.pay = function(e) {
    if (!this.selected_value) { this.error = true; return; }
    var f = document.querySelector("#donation-form");
    f.querySelector("[name=cmd]").value=e.target.dataset.cmd;
    f.submit();
  }.bind(this)
});

riot.tag2('event-list', '<h2 if="{_c}">{_c} Upcoming Event{_s}:</h2> <h2 if="{!_c}">No Upcoming Events</h2> <event-occurrence each="{occurrences}"></event-occurrence>', '', '', function(opts) {

  this.event = this.opts.event;
  this.occurrences = this.event.upcoming_occurrences;
  this.user_reservations = opts.user_reservations || [];
  this.on("update", function() {
    this._c = this.event.upcoming_occurrences.length;
    this._s = (this._c > 1)?"s":"";
    uR.forEach(this.occurrences,function(o) { o.quantity = this.user_reservations[o.id] || 0; }.bind(this));
  });
});

riot.tag2('event-occurrence', '<div class="well d-flex align-items-center justify-content-between" name="ajax_target" style="min-width:210px"> <div class="column"> <div class="dates"> <div>{start_string}</div> </div> <b class="full" if="{full}">This occurrence is full</b> <div if="{!past && parent.event.allow_rsvp}"> <div if="{!full && authenticated}"> <button class="btn btn-success rsvp" if="{!quantity}" onclick="{makeRSVP}">RSVP for this event</button> <button class="btn btn-danger unrsvp" if="{quantity}" onclick="{cancelRSVP}">Cancel RSVP</button> <span if="{total_rsvp && uR.auth.user.is_superuser}" data-reddot="{total_rsvp}"></span> </div> <div if="{!full && !authenticated}"> You must <a href="{uR.urls.auth.login}">Login</a> to RSVP </div> <div if="{past}"> <span class="btn btn-warning">You can no longer RSVP</span> </div> </div> <div class="column"> <div><a if="{uR.auth.user.is_superuser}" href="/event/orientations/{start_slug}/" class="admin-link fa fa-pencil-square"></a></div> <div><a if="{uR.auth.user.is_staff && parent.event.allow_rsvp}" href="/tools/master/event/rsvp/?object_id={id}" class="admin-link fa fa-check-square-o" style="top: 30px; bottom: 0;"></a></div> </div> </div>', '', '', function(opts) {

  var self = this;
  this.on('mount',function() {
    var start = moment(this.start);
    this.start_string = start.format("ddd MMM D, ");
    this.start_string += uR.formatTimeRange(this.start,this.end);
    this.start_slug = start.format("YYYY/MM/DD");

    uR.auth.ready(function() {
      this.authenticated = uR.auth.user;
      this.update();
    }.bind(this));
  });
  this.updateRSVP = function(item,quantity) {
    item.quantity = quantity;
    this.ajax({
      url: '/event/rsvp/',
      data: {occurrence_id: item.id,quantity: quantity},
      success: function(data) {
        this.parent.user_reservations = data;
        this.update();
        this.parent.update();
      },
      error: function(data) { uR.alert(data.error); }
    });
  }.bind(this)
  this.makeRSVP = function(e) {
    this.updateRSVP(e.item,e.item.quantity+1);
  }.bind(this)
  this.cancelRSVP = function(e) {
    this.updateRSVP(e.item,0);
  }.bind(this)
});
